import React from "react";
import styles from "./styles.module.css";
import LogoSVG from "../../../images/logo1.svg";

export default function Footer() {
   return (
      <div className={styles.borderContainer}>
         <div className={styles.container}>
            <div className={styles.logoContainer}>
               <img className={styles.logo} src={LogoSVG} alt="Logo" />
               {/* {isMobile ? (
                  <p>Powered by Famoco</p>
               ) : (
                  <p>
                     Powered by <br /> Famoco
                  </p>
               )} */}
            </div>
            <div className={styles.copyrightContainer}>
               <p>Copyright © Lizar Systems. All rights reserved.</p>
               <p>
                  DRACO1 and Lizar Systems are registered and trademarks
                  owned by Lizar Systems.
               </p>
            </div>
         </div>
      </div>
   );
}
